import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import logo from './component/assests/logo.svg'
import reportWebVitals from './reportWebVitals';
import {  HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './component/redux/store';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
const root = ReactDOM.createRoot(document.getElementById('root'));
const App = lazy(() => import('./App'))
root.render(

  <Provider store={store}>
    <Suspense fallback={
        <div className='main_app2' style={{display:"flex", justifyContent:"center", alignItems:"center" , width:"100%", height:"100vh"}} > 
        <img src={logo} alt=''style={{position:"absolute"}} />
        <CircularProgress size={'5rem'} /></div>}>
      <React.StrictMode>
        <HashRouter>
          {/* <App /> */}
          <App />
          <ToastContainer newestOnTop />
        </HashRouter>
      </React.StrictMode>
    </Suspense>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
